/*jslint browser: true*/
/*eslint-env browser*/
/*global jQuery*/

jQuery(document).ready(function ($) {
	'use strict';
	
	$(document).foundation();

	// slider
	$('.sliderr .owl-carousel').owlCarousel(
		{
			items: 1,
			loop: true,
			autoplay: true,
			smartSpeed: 600,
			autoplayHoverPause: true,
			nav: false,
			dots: true,
			thumbs: true,
			thumbsPrerendered: true,
			navText: ["<i class='fa fa-angle-left' aria-hidden='true'></i>","<i class='fa fa-angle-right' aria-hidden='true'></i>"],
		}
	);


	// gomb felirat csere klikkre
	/*$('.korvonal input[type="submit"]').on('click', function(){
		$(this).attr('value', 'Feldolgozás...');
		setTimeout(function(){
			$('.korvonal input[type="submit"]').attr('value', 'Elküld');
		  }, 1000);
	});*/


	// kereses gomb
	$('.fa.fa-search').click( function() {
		$('#kereses').toggleClass('open');
	});

	$('.gomb.show').click( function() {
		$('#palyazat').toggle();
	});

	//ha input value Nem a parent textarea readonly
	$('.page-template-forms .frm_fields_container input[value=Nem]').each( function() {
		$(this).prev().children('textarea').attr('readonly', 'readonly');
	});

	//ha slide value nem éri el a minimumot akkor piros
	$('.page-template-forms .frm_fields_container input[type=hidden]').each( function() {
		if ($(this).is('[data-frmval]')) {
			var $minertek = parseInt($(this).val());
			var $miben = $(this).prev().prev().children().children();
			var $ertek = parseInt($miben.text());
			console.log($minertek);
			console.log($ertek);
			if ( $minertek > $ertek ) {
				//$(this).prev().prev().children().children().addClass('piros');
				$(this).prev().prev().children().addClass('piros');
			}
			$miben.on( 'change', function () {
				var $ertekk = $miben.text();
				console.log($ertekk);
				if ( $minertek > $ertekk ) {
					//$(this).prev().prev().children().children().addClass('piros');
					$miben.parent().addClass('piros');
				} else {
					$miben.parent().removeClass('piros');
				}
			})
		}
	});

	//ha slide value nem éri el a minimumot a listában akkor <p> piros
	$('.page-template-forms .entry-content span.min').each( function() {
		var $minlistertek = parseInt($(this).text());
		var $mibenlist = $(this).next().next();
		var $pont = parseInt($mibenlist.text());
		console.log($minlistertek, $pont);
		if ( $minlistertek > $pont ) {
			$(this).parent().addClass('piros');
		}
	});
	
});